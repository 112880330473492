import { post, get } from "@/utils/http";
import url from "./url_bank";

//总体经营搜索
export function post_enterprise_yh_ztjy(params) {
    return post({ url: url.enterprise_yh_ztjy, params })
}
//总体经营指标选择(客户存款总额)
export function post_enterprise_yh_kfckze(params) {
    return post({ url: url.enterprise_yh_kfckze, params })
}
//总体经营指标选择(客户贷款及垫款总额)
export function post_enterprise_yh_kfdk(params) {
    return post({ url: url.enterprise_yh_kfdk, params })
}

//工商信息详情 银行
export function post_enterprise_yh_gsxx(params) {
    return post({ url: url.enterprise_yh_gsxx, params })
}
//股东信息列表 银行
export function post_enterprise_yh_gdxxList(params) {
    return post({ url: url.enterprise_yh_gdxxList, params })
}
//对外投资列表 银行
export function post_enterprise_yh_dwtzList(params) {
    return post({ url: url.enterprise_yh_dwtzList, params })
}