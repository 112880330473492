const url = {
    enterprise_yh_ztjy: '/service-insight/enterprise/yh/ztjy', //总体经营搜索
    enterprise_yh_kfckze: '/service-insight/enterprise/yh/kfckze', //总体经营指标选择(客户存款总额)
    enterprise_yh_kfdk: '/service-insight/enterprise/yh/kfdk', //总体经营指标选择(客户贷款及垫款总额)
    enterprise_yh_gsxx: '/service-insight/enterprise/yh/gsxx', //工商信息详情
    enterprise_yh_gdxxList: '/service-insight/enterprise/yh/gdxxList', //股东信息列表
    enterprise_yh_dwtzList: '/service-insight/enterprise/yh/dwtzList', //对外投资列表
}
export default url








