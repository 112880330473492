<template>
  <div class="home">
    <!-- <Tabs v-model="tab">
      <TabPane label="总体经营" name="tab1"></TabPane>
      <TabPane label="行业贷款" disabled name="tab3"></TabPane>
      <TabPane label="产品贷款" disabled></TabPane>
      <TabPane label="贷款按类型分" disabled></TabPane>
      <TabPane label="贷款按担保方式分" disabled></TabPane>
      <TabPane label="逾期贷款" disabled></TabPane>
    </Tabs> -->
    <Row style="margin-bottom: 20px">
      <Col span="16">
        <Card class="left_card">
          <Form :model="searchForm" :label-width="120">
            <FormItem label="周期">
              <Select
                v-model="searchForm.timeDim"
                style="width: 80px"
                @on-change="
                  (value) => {
                    this.handleChangeCycle('周期', 'string', 'timeDim', value);
                  }
                "
              >
                <Option
                  v-for="item in zqList"
                  :value="item.name"
                  :key="item.id"
                  >{{ item.name }}</Option
                > </Select
              >&nbsp;
              <DateRange
                v-model="searchForm.timeStr"
                :dateType="dateType"
                ref="DateRange"
                @change="
                  (value) =>
                    this.handleChangeOption('日期', 'date', 'timeStr', value)
                "
              ></DateRange>
            </FormItem>
            <FormItem label="指标选择">
              <div class="select-card">
                <Button>
                  存款({{ checkbox_1.length }}/{{ checkboxList.kfckze.length }})
                  <Icon type="md-arrow-dropdown" />
                </Button>
                <div class="select-card__content">
                  <div class="input-header">
                    <Input
                      v-model="inputKeyArray.kfckze"
                      placeholder="输入关键字搜索"
                      style="width: 360px"
                      clearable
                    ></Input
                    >&nbsp;
                    <Button
                      type="primary"
                      @click="() => this.handleSearchCheckbox('kfckze')"
                      >搜索</Button
                    >
                  </div>
                  <OldCheckboxGroupAll
                    v-model="checkbox_1"
                    ref="OldCheckboxGroupAll"
                    @change="
                      (data) =>
                        this.handleChangeValue('存款', 'list', 'kfckze', data)
                    "
                    :list="checkboxList.kfckze"
                    :checkboxList="checkboxAllList.kfckze"
                  ></OldCheckboxGroupAll>
                </div>
              </div>
              <div class="select-card">
                <Button>
                  贷款({{ checkbox_2.length }}/{{ checkboxList.kfdk.length }})
                  <Icon type="md-arrow-dropdown" />
                </Button>
                <div class="select-card__content">
                  <div class="input-header">
                    <Input
                      v-model="inputKeyArray.kfdk"
                      placeholder="输入关键字搜索"
                      style="width: 360px"
                      clearable
                    ></Input
                    >&nbsp;
                    <Button
                      type="primary"
                      @click="() => this.handleSearchCheckbox('kfdk')"
                      >搜索</Button
                    >
                  </div>
                  <OldCheckboxGroupAll
                    v-model="checkbox_2"
                    ref="OldCheckboxGroupAll2"
                    @change="
                      (data) =>
                        this.handleChangeValue('贷款', 'list', 'kfdk', data)
                    "
                    :list="checkboxList.kfdk"
                    :checkboxList="checkboxAllList.kfdk"
                  ></OldCheckboxGroupAll>
                </div>
              </div>
            </FormItem>
            <FormItem>
              <Button class="searchBtnLog" data-id="1" type="primary" @click="handleSearch">查询</Button>
            </FormItem>
          </Form>
        </Card>
      </Col>
      <Col span="8">
        <div class="choose_box">
          <div class="choose_box_title">
            已选指标：
            <span @click="clearAll">清空</span>
          </div>
          <div class="choose_box_content">
            <template v-if="Object.keys(selectedList).length !== 0">
              <template v-for="item in selectedList">
                <span v-if="item !== null" :key="item.formItem">
                  <Tag
                    v-for="(name, index) in item.data"
                    :key="name"
                    type="border"
                    closable
                    @on-close="() => handleClose(index, item)"
                  >
                    <span v-if="item">{{ item.title }}:</span>
                    <span>{{ name }}</span>
                  </Tag>
                </span>
              </template>
            </template>
          </div>
        </div>
      </Col>
    </Row>
  </div>
</template>

<script>
import { post_enterprise_yh_kfckze, post_enterprise_yh_kfdk } from "@/api/bank";
import OldCheckboxGroupAll from "@/components/OldCheckboxGroupAll";
import dateHandleMixin from "@/views/share/dateHandleMixin";
import { checkDate } from "@/utils/index";
export default {
  name: "SearchPanel",
  components: {
    OldCheckboxGroupAll,
  },
  mixins: [dateHandleMixin],
  computed: {
    code() {
      return this.$store.state.componeyCode;
    },
  },
  beforeMount() {
    this.$Notice.destroy();
  },
  mounted() {
    this.init();
  },
  data() {
    return {
      checkbox_1: [],
      checkbox_2: [],
      searchForm: {
        timeDim: "年度",
        indexList: [],
        size: 10,
        current: 1,
        timeStr: ["2017", "2020"], // 日期
      },
      tab: "tab1",
      dateType: "year",
      zqList: [
        // {
        //   id: "季度",
        //   name: "季度",
        // },
        {
          id: "半年度",
          name: "半年度",
        },
        {
          id: "年度",
          name: "年度",
        },
      ],
      checkboxList: {
        kfckze: [],
        kfdk: [],
      },
      checkboxAllList: {
        kfckze: [],
        kfdk: [],
      },
      inputKeyArray: {
        kfckze: "",
        kfdk: "",
      },
      selectedList: {}, //已选条件
    };
  },
  methods: {
    init() {
      const promise1 = this.getCheckboxList("kfckze", { code: this.code });
      const promise2 = this.getCheckboxList("kfdk", { code: this.code });
      Promise.all([promise1, promise2]).then(() => {
        setTimeout(() => {
          this.handleSearch();
        }, 1000);
      });
    },
    getCheckboxList(type, requestParam) {
      return new Promise((resolve) => {
        const requestType = {
          kfckze: post_enterprise_yh_kfckze,
          kfdk: post_enterprise_yh_kfdk,
        };
        requestType[type](requestParam)
          .then((data) => {
            this.handleCheckbox(data, type);
            if (type == "kfckze") {
              this.$nextTick(() => {
                this.$refs.OldCheckboxGroupAll.handleCheckAll();
                resolve();
              });
            } else {
              this.$nextTick(() => {
                this.$refs.OldCheckboxGroupAll2.handleCheckAll();
                resolve();
              });
            }
          })
          .catch((e) => {
          });
      });
    },
    handleSearchCheckbox(type) {
      this.getCheckboxList(type, {
        code: this.code,
        indexName: this.inputKeyArray[type],
      });
    },
    handleCheckbox(data, name) {
      let list = [];
      let showList = [];
      data.map((item) => {
        showList.push({
          id: item.tableColumn,
          name: item.tableColumnCn,
        });
        list.push(item.tableColumn);
      });
      this.checkboxList[name] = showList;
      this.checkboxAllList[name] = list;
    },
    handleChangeValue(name, type, formItem, value) {
      const checkedList = value;
      const checkboxList = this.checkboxList[formItem];
      let list = [];
      checkedList.map((item) => {
        for (let i = 0, len = checkboxList.length; i < len; i++) {
          if (item === checkboxList[i].id) {
            list.push(checkboxList[i].name);
            break;
          }
        }
      });
      this.handleChangeOption(name, type, formItem, list);
    },
    handleChangeCycle(name, type, formItem, value) {
      this.searchForm[formItem] = value;
      if (value === "年度") {
        this.dateType = "year";
      } else {
        this.dateType = "year";
      }
      this.searchForm.timeStr = ["", ""];
      this.$refs.DateRange.clearAll();
      delete this.selectedList.timeStr;
    },
    clearAll() {
      this.checkbox_1 = [];
      this.checkbox_2 = [];
      this.selectedList = {};
    },
    handleCheckboxValue(value, type) {
      const checkedList = value;
      const checkboxList = this.checkboxList[type];
      let list = [];
      checkedList.map((item) => {
        for (let i = 0, len = checkboxList.length; i < len; i++) {
          if (item === checkboxList[i].id) {
            list.push({
              tableColumn: checkboxList[i].id,
              tableColumnCn: checkboxList[i].name,
            });
            break;
          }
        }
      });
      return list;
    },
    handleChangeOption(name, type, formItem, value) {
      let data = [];
      let choosedType = "string";
      switch (type) {
        case "string":
          data = [value];
          choosedType = "string";
          break;
        case "date":
          if (value[0].length === 0 && value[1].length === 0) {
            this.handleDelete(formItem);
            return;
          }
          data = [`${value[0]} 至 ${value[1]}`];
          choosedType = "date";
          break;
        case "list":
          choosedType = "list";
          data = value;
          break;
        default:
          choosedType = "string";
          data = value;
      }
      if (value.length !== 0) {
        this.selectedList[formItem] = {
          title: name,
          choosedType,
          type,
          formItem,
          data,
        };
      } else {
        this.handleDelete(formItem);
      }
    },
    handleDelete(type) {
      let selectedList = Object.assign({}, this.selectedList);
      selectedList[type] = null;
      this.selectedList = selectedList;
      this.searchForm[type] = [];
    },
    handleClose(index, item) {
      const type = item.formItem;
      const { choosedType, data } = item;
      const checkboxType = {
        kfckze: "checkbox_1",
        kfdk: "checkbox_2",
      };
      const formData = this[checkboxType[type]];
      if (choosedType === "list") {
        if (data.length > 1) {
          let selectedList = Object.assign({}, this.selectedList);
          data.splice(index, 1);
          formData.splice(index, 1);
          selectedList[type].data = data;
          this.selectedList = selectedList;
          this[checkboxType[type]] = formData;
          return;
        } else {
          let selectedList = Object.assign({}, this.selectedList);
          selectedList[type] = null;
          this.selectedList = selectedList;
          this[checkboxType[type]] = [];
        }
      }
      let selectedList = Object.assign({}, this.selectedList);
      selectedList[type] = null;
      this.selectedList = selectedList;
      if (choosedType === "string") {
        this.searchForm[type] = "";
      } else {
        this.searchForm[type] = [];
      }
    },
    checkData(data) {
      const { timeStr } = data;
      if (!data.timeStr) {
        this.$msg.error({ type: "remind", code: 4011 });

        return false;
      }
      if (!checkDate(timeStr[0], timeStr[1])) {
        this.$msg.error({ type: "remind", code: 4015 });

        return false;
      }
      if (data.indexList.length == 0) {
        this.$msg.error({ type: "remind", code: 4020 });

        return false;
      }

      return true;
    },
    handleSearch() {
      const { timeStr } = this.searchForm;
      let searchForm = this.handleDateRule(
        timeStr,
        "timeStr",
        "报告期",
        this.searchForm
      );
      if (searchForm === null) {
        return;
      }
      const checkboxArray = [
        {
          type: 1,
          class: "kfckze",
        },
        {
          type: 2,
          class: "kfdk",
        },
      ];
      let indexList = [];
      let typeName;
      for (let i = 0, len = checkboxArray.length; i < len; i++) {
        typeName = this[`checkbox_${checkboxArray[i].type}`];
        if (typeName.length !== 0) {
          indexList.push({
            type: checkboxArray[i].type,
            indexs: this.handleCheckboxValue(typeName, checkboxArray[i].class),
          });
        }
        if (searchForm.hasOwnProperty(checkboxArray[i].class)) {
          delete searchForm[checkboxArray[i].class];
        }
      }
      searchForm.indexList = indexList;
      if (!this.checkData(searchForm)) return;
      this.$emit("submit", searchForm);
    },
  },
};
</script>

<style lang="scss">
.left_card {
  height: 210px;
}
.select-card {
  display: inline-block;
  margin: 0 10px 0 0;
}
.ivu-tabs-bar {
  border: 1px solid #dcdee2 !important;
}
.select-card__content {
  padding: 8px 16px;
  background: #fff;
  visibility: visible;
  font-size: 14px;
  position: absolute;
  z-index: 1060;
  width: 800px;
  min-height: 200px;
  overflow: scroll;
  position: absolute;
  will-change: top, left;
  top: 34px;
  left: -43px;
  background-clip: padding-box;
  border-radius: 4px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
  display: none;
}
.select-card:hover {
  .select-card__content {
    display: block;
  }
}
.checkbox-title {
  color: #1562d6;
  border-left: 2px solid #1562d6;
  line-height: 20px;
  padding-left: 10px;
  margin: 10px 0;
}
.choose_box {
  border: 1px solid #d6d7d9;
  border-radius: 10px;
  margin-left: 10px;
  .choose_box_title {
    padding: 8px 30px;
    color: #666666;
    border-radius: 10px 10px 0 0;
    border-bottom: 1px solid #d6d7d9;
    background: rgba(250, 250, 253, 1);
    display: flex;
    justify-content: space-between;
  }
  .choose_box_content {
    height: 170px;
    overflow: auto;
    padding: 10px;
  }
}
</style>
