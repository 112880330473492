<template>
  <div>

    <Row>
      <Col :span="showMore ? '22' : '24'">
      <div style="display:inline">
        <Checkbox :value="checkAll"
                  @click.prevent.native="handleCheckAll">全选</Checkbox>
      </div>
      <CheckboxGroup v-model="checkAllGroup"
                     @on-change="checkAllGroupChange"
                     style="display:inline">
        <template v-for="(item, index) in list">
          <template v-if="!viewMore && showMore">
            <Checkbox :key="item.id"
                      :label="item.id"
                      v-if="index < 18">{{item.name}}</Checkbox>
          </template>
          <template v-else>
            <Checkbox :key="item.id"
                      :label="item.id">{{item.name}}</Checkbox>
          </template>
        </template>
      </CheckboxGroup>
      </Col>
      <Col span="2"
           style="text-align:right"
           v-if="showMore">
      <span @click="handleViewMore"
            v-if="list.length >= 17">查看更多
        <Icon type="ios-arrow-down"
              style="color:#00B6FF"
              v-if="!viewMore" />
        <Icon type="ios-arrow-up"
              style="color:#00B6FF"
              v-else />
      </span>
      </Col>
    </Row>

  </div>
</template>
<script>
export default {
  model: {
    prop: 'checked',
    event: 'change'
  },
  props: {
    checked: Array,
    list: Array,
    checkboxList: Array,
    showMore: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      checkAll: false,
      checkAllGroup: this.checked,
      viewMore: false,
    }
  },
  watch: {
    checked (newValue, oldValue) {
      this.checkAllGroup = newValue;
      this.checkAll = newValue.length === this.list.length;
    }
  },
  methods: {
    handleCheckAll () {
      this.checkAll = !this.checkAll;
      if (this.checkAll) {
        const list = this.checkboxList.slice(0);
        this.checkAllGroup = list;
        this.$emit('change', list);
        this.$emit('change-all', list);
      } else {
        this.checkAllGroup = [];
        this.$emit('change', []);
      }
    },
    checkAllGroupChange (data) {
      if (data.length === this.list.length) {
        this.indeterminate = false;
        this.checkAll = true;
      } else if (data.length > 0) {
        this.indeterminate = true;
        this.checkAll = false;
      } else {
        this.indeterminate = false;
        this.checkAll = false;
      }
      this.$emit('change', data);
    },
    handleViewMore () {
      this.viewMore = !this.viewMore;
    },
  }
}
</script>
