<template>
  <div class="right-box">
    <dataplat-search ref="dataplatSearch" @submit="handleSubmit"></dataplat-search>
    <DownloadBtn
      :id="1"
      :type=1
      :style="{
        position: 'relative',
        left: 0,
        'margin-bottom': '20px',
        'background-color': 'rgba(241, 110, 113, 1)',
      }"
      @exportData="exportData"
    >
    </DownloadBtn>
    <span :style="{ position: 'absolute', top: '370px', right: '35px' }">
      <span style="padding-right: 15px">单位：百万元</span>币种：人民币
    </span>
    <dataplat-content
      :columns="tableColumns"
      :list="tableList"
      :total="total"
      @changePage="handleChangePage"
      @changePageSize="handleChangePageSize"
    ></dataplat-content>
  </div>
</template>

<script>
import SearchPanel from "./components/operate/SearchPanel";
import ContentPanel from "@/components/ContentPanel";
import DownloadBtn from "@/components/DownloadBtn";
import { post_enterprise_yh_ztjy } from "@/api/bank";
import { downloadData, formatJson } from "@/utils/index";
import dataReports from "@/mixins/dataReports";
import { returnFloat } from "@/utils";

export default {
  name: "reportForm",
  mixins: [dataReports],
  components: {
    "dataplat-search": SearchPanel,
    "dataplat-content": ContentPanel,
    DownloadBtn,
  },
  computed: {
    code() {
      return this.$store.state.componeyCode;
    },
  },
      watch: {
        code(newVal, oldVal) {
            if (newVal !== oldVal && this.$route.query.code) {
                this.code = this.$route.query.code;
                // this.init();
            }
            this.$refs.dataplatSearch.handleSearch()
        }
    },
  data() {
    return {
      tableColumns: [],
      tableList: [],
      total: 0,
      searchForm: {},
      to100:[
        '不良贷款率',
        '公司类贷款及贴现不良贷款率',
        '公司不良贷款率',
        '票据贴现不良贷款率',
        '个人不良贷款率',
        '境外不良贷款率',
      ],
    };
  },
  methods: {
    handleChangePage(page) {
      this.searchForm.current = page;
      this.handleSubmit(this.searchForm);
    },
    handleChangePageSize(page) {
      this.searchForm.size = page;
      this.handleSubmit(this.searchForm);
    },
    handleSubmit(params) {
      this.searchForm = params;
      let requestParam = Object.assign(params, {
        code: this.code, // 股票代码
      });
      post_enterprise_yh_ztjy(requestParam)
        .then((data) => {
          const tableColumns = data.heads || [];
          let columns = [];
          let fixed = false;
          tableColumns.map &&
            tableColumns.map((item) => {
              fixed = item.indexOf("k") >= 0;
              columns.push({
                  //表头
                title: fixed ? ` ` : item,
                key: item,
                fixed: fixed ? "left" : "",
                align: "center",
                width: fixed ? 160 : "",
                minWidth: fixed ? 160 : 120,
              });
            });
          this.tableColumns = columns;
          this.tableList = data.records;
          this.total = data.total;
          this.tableList.forEach((i) => {
           if(this.to100.includes(i.k2)){
             //处理数据格式(为百分之)
            for (let key in i) {
              if (!isNaN(i[key])) {
                i[key] = returnFloat(i[key]*100, 2)+'%'
              }
            }
           }else{
              //处理数据格式
            for (let key in i) {
              if (!isNaN(i[key])) {
                  //普通的数据格式
                if (i[key].search("E") == -1) {
                  i[key] = returnFloat(i[key], 2);
                } else {
                    //科学计数法下的数据格式
                  let index = i[key].search("E");
                  let e = i[key].slice(index + 1, index + 3);
                  i[key] = returnFloat(i[key].substring(0,i[key].indexOf('E')) * Math.pow(10,e),2)
                }
              }
            }
           }

          });
        })
        .catch((e) => {
        });
    },
    exportData() {
      const params = this.searchForm;
      params.size = 1000000;
      params.current = 1;
      let requestParam = Object.assign(params, {
        code: this.code, // 股票代码
      });
      post_enterprise_yh_ztjy(requestParam).then((data) => {
        let filterVal = [];
        let tHeader = [];
        this.tableColumns.forEach &&
          this.tableColumns.forEach((item) => {
            tHeader.push(item.title);
            filterVal.push(item.key);
          });
        const resData = formatJson(filterVal, data.records);
        downloadData(tHeader, resData, "运营统计");
      });
    },
    handleViewMore(id) {
      this.$router.push({
        path: `/Insight/houseEnterprise/mergerAcquisitionDetails`,
        query: {
          id: id,
        },
      });
    },
  },
};
</script>
